

<div class="grid main-grid">
    <div class="col-12 right max-w-full">
        <div class="breadcrumb">
            <h3>Báo cáo tổng</h3>
            <p-breadcrumb [model]="items"></p-breadcrumb>
        </div>
     
        <div class="grid-default" #container>
            <p-card styleClass="mb-3">

                <div class="row d-flex justify-content-center">
                  <div class="col-md-6">
                    <div class="field-group select" [ngClass]="query.report_type ? 'valid' : 'invalid' ">
                      <label for="">Loại báo cáo</label>
                      <p-dropdown appendTo="body" (onChange)="getDetailReport($event)" [baseZIndex]="100" [autoDisplayFirst]="false" [options]="listReports"
                        [(ngModel)]="query.report_type" name="report_type" [filter]="true">
                        <ng-template let-item pTemplate="selectedItem">
                          <span>{{item.label}}</span>
                        </ng-template>
                        <ng-template let-car pTemplate="item">
                          <div class="ui-helper-clearfix" >
                            <div style="font-size:14px;float:left;margin-top:4px">{{car.label}}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </div>
                </div>

                <div class="row d-flex justify-content-center mt-3 chiTietThamSoBaoCao" *ngIf="chiTietThamSoBaoCao">
                  <div class="col-md-6" style="padding-left: 0px;padding-right: 0px;">
                    <ng-container *ngFor="let paramater of chiTietThamSoBaoCao?.paramaters">
                      <div *ngIf="paramater?.param_cd === 'exporttype'">
                        <div class="col-md-12 d-flex mb-3">
                          <button pButton pRipple type="button" [disabled]="query.report_type===null ? true : false" 
                          label="Xem file Excel" class="mr-2" icon="pi pi-eye" (click)="ViewExcel('view')"></button>
                          <button pButton pRipple type="button" [disabled]="query.report_type===null ? true : false" 
                          label="Xuất file Excel" class="mr-2"  icon="pi pi-file-excel" (click)="exportExcel('xlsx')"></button>
                          <button pButton pRipple type="button" [disabled]="query.report_type===null ? true : false" 
                          label="Xuất file PDf"  class="mr-2"  icon="pi pi-file-pdf"(click)="exportPdf('pdf')"></button>
                        </div>
                      </div>
                      <div *ngIf="paramater?.param_type === 'datetime'" class="col-md-6">
                          <div class="field-group date label-8">
                            <label for="">{{paramater?.param_name}}: </label>
                            <p-calendar placeholder="DD/MM/YYYY" appendTo="body" [baseZIndex]="101"
                              [(ngModel)]="paramater[paramater?.param_cd]" name="{{paramater?.param_cd}}"
                              [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030" inputId="navigators"
                              dateFormat="dd/mm/yy" name="datereport">
                            </p-calendar>
                          </div>
                      </div>
                      <div *ngIf="paramater?.param_type === 'object'" class="col-md-6">
                          <div class="field-group select" [ngClass]="paramater[paramater?.param_cd] ? 'valid' : 'invalid' ">
                            <label for="">{{paramater?.param_name}}: </label>
                            <p-dropdown appendTo="body" [baseZIndex]="100" [autoDisplayFirst]="false"
                              [options]="paramater.options" [(ngModel)]="paramater[paramater?.param_cd]"
                              name={{paramater.param_cd}} [filter]="true">
                              <ng-template let-item pTemplate="selectedItem">
                                <span>{{item.label}}</span>
                              </ng-template>
                              <ng-template let-car pTemplate="item">
                                <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                  <div style="font-size:14px;float:left;margin-top:4px">{{car.label}}</div>
                                </div>
                              </ng-template>
                            </p-dropdown>
                          </div>
                      </div>
                      <div *ngIf="paramater?.param_type === 'multiSelect'" class="col-md-12">
                        <div class="field-group multi-select" [ngClass]="paramater[paramater?.param_cd] ? 'valid' : 'invalid' ">
                          <label for="">{{paramater?.param_name}}: </label>
                          <p-multiSelect [options]="paramater.options" [showToggleAll]="true"  (onChange)="selectChangeMultiSelect($event, paramater.param_cd)"
                          [(ngModel)]="paramater[paramater?.param_cd]" optionLabel="name"  name={{paramater.param_cd}} [virtualScroll]="true"
                            [filter]="true" [itemSize]="34" class="multiselect-custom-virtual-scroll">
                            <ng-template let-country pTemplate="item">
                                <div class="country-item">
                                    <div>{{country.name}}</div>
                                </div>
                            </ng-template>
                        </p-multiSelect>
                        </div>
                    </div>
                    </ng-container>

                  </div>

                </div>
              </p-card>
        </div>
    </div>
</div>