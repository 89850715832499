<div class="content-body">
    <div class="grid">
      <div class="col-12">
        <div class="col-12">
            <div class="select-ori mr-2 float-left">
              <div class="name">
                  <div class="field-group select mb-0 valid" [ngClass]="">
                      <p-dropdown panelStyleClass="select-ori-panel" placeholder="Chọn tổ chức" appendTo="body" [baseZIndex]="100" [autoDisplayFirst]="false" 
                          [options]="organs" [(ngModel)]="queryDashboard.orgid" name="OrganizeId" (onChange)="getDashboardInfo()">
                          <ng-template let-item pTemplate="selectedItem">
                              <span style="vertical-align:middle;">{{item.label}}</span>
                          </ng-template>
                          <ng-template let-car pTemplate="item">
                              <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                  <div style="">{{car.label}}</div>
                              </div>
                          </ng-template>
                      </p-dropdown>
                  </div>
              </div>
              <div class="dia" (click)="hrDiagram()">
                  <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0C8.92893 0 7.25 1.67148 7.25 3.73335C7.25 5.54325 8.54366 7.05234 10.2606 7.39415V10.5496H4.49291C4.29682 10.5496 4.10876 10.6272 3.9701 10.7652C3.83144 10.9033 3.75354 11.0905 3.75354 11.2857V14.8684H1.23937C1.04328 14.8684 0.855215 14.946 0.716557 15.084C0.577898 15.2221 0.5 15.4093 0.5 15.6045V17.7639C0.5 17.9591 0.577898 18.1464 0.716557 18.2844C0.855215 18.4224 1.04328 18.5 1.23937 18.5C1.43546 18.5 1.62353 18.4224 1.76218 18.2844C1.90084 18.1464 1.97874 17.9591 1.97874 17.7639V16.3406H7.00709V17.7639C7.00709 17.9591 7.08498 18.1464 7.22364 18.2844C7.3623 18.4224 7.55036 18.5 7.74646 18.5C7.94255 18.5 8.13061 18.4224 8.26927 18.2844C8.40793 18.1464 8.48583 17.9591 8.48583 17.7639V15.6045C8.48583 15.4093 8.40793 15.2221 8.26927 15.084C8.13061 14.946 7.94255 14.8684 7.74646 14.8684H5.23229V12.0218H16.7677V14.8684H14.2535C14.0575 14.8684 13.8694 14.946 13.7307 15.084C13.5921 15.2221 13.5142 15.4093 13.5142 15.6045V17.7639C13.5142 17.9591 13.5921 18.1464 13.7307 18.2844C13.8694 18.4224 14.0575 18.5 14.2535 18.5C14.4496 18.5 14.6377 18.4224 14.7764 18.2844C14.915 18.1464 14.9929 17.9591 14.9929 17.7639V16.3406H20.0213V17.7639C20.0213 17.9591 20.0992 18.1464 20.2378 18.2844C20.3765 18.4224 20.5645 18.5 20.7606 18.5C20.9567 18.5 21.1448 18.4224 21.2834 18.2844C21.4221 18.1464 21.5 17.9591 21.5 17.7639V15.6045C21.5 15.4093 21.4221 15.2221 21.2834 15.084C21.1448 14.946 20.9567 14.8684 20.7606 14.8684H18.2465V11.2857C18.2465 11.0905 18.1686 10.9033 18.0299 10.7652C17.8912 10.6272 17.7032 10.5496 17.5071 10.5496H11.7394V7.39415C13.4563 7.05234 14.75 5.54325 14.75 3.73335C14.75 1.67148 13.0711 0 11 0Z" fill="#0979FD"/>
                  </svg>
              </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- row 1 -->
    <div class="grid boxs">
      <div class="col-9 pr-0">
        <div class="grid">
            <div class="col-3 pt-0" *ngFor="let tongQuanNhanSu of dashboardData?.tongquan">
              <div class="card">
                <div class="card-content">
                  <div class="icon d-flex middle center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.414 7.02347C8.414 5.07744 10.0196 3.5 12 3.5C13.9804 3.5 15.586 5.07744 15.586 7.02347V7.92546C15.586 9.87149 13.9804 11.4489 12 11.4489C10.0196 11.4489 8.414 9.87149 8.414 7.92546V7.02347Z" fill="#FF7C59"/>
                      <path d="M6.18961 13.2051C6.16381 13.2046 6.13793 13.2043 6.11198 13.2043H3.89606C2.02054 13.2043 0.5 14.6982 0.5 16.5411V17.412C0.5 18.293 1.22686 19.0071 2.12333 19.0071H4.50679V16.8126C4.50679 15.3702 5.15924 14.0781 6.18961 13.2051Z" fill="#FF7C59"/>
                      <path d="M17.8104 13.2051C18.8408 14.0781 19.4932 15.3702 19.4932 16.8126V19.0071H21.8767C22.7731 19.0071 23.5 18.293 23.5 17.412V16.5411C23.5 14.6982 21.9795 13.2043 20.1039 13.2043H17.888C17.8621 13.2043 17.8362 13.2046 17.8104 13.2051Z" fill="#FF7C59"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0362 19.0918V16.8126C18.0362 16.0106 17.7472 15.2746 17.2657 14.6998C16.6445 13.9582 15.703 13.4851 14.6483 13.4851H9.35166C7.47946 13.4851 5.9638 14.976 5.9638 16.8126V19.0918C6.20934 19.906 6.97642 20.5 7.88472 20.5H16.1153C17.0236 20.5 17.7907 19.906 18.0362 19.0918Z" fill="#FF7C59"/>
                      <path d="M5.00402 6.20445C3.49565 6.20445 2.27274 7.4059 2.27274 8.88812V9.5724C2.27274 11.0546 3.49565 12.2561 5.00402 12.2561C6.5124 12.2561 7.73531 11.0546 7.73531 9.5724V8.88812C7.73531 7.4059 6.5124 6.20445 5.00402 6.20445Z" fill="#FF7C59"/>
                      <path d="M16.2647 8.88812C16.2647 7.4059 17.4876 6.20445 18.996 6.20445C20.5044 6.20445 21.7273 7.4059 21.7273 8.88812V9.5724C21.7273 11.0546 20.5044 12.2561 18.996 12.2561C17.4876 12.2561 16.2647 11.0546 16.2647 9.5724V8.88812Z" fill="#FF7C59"/>
                      </svg>
                  </div>
                  <div class="media align-items-stretch">
                      <p>{{ tongQuanNhanSu?.name }}</p>

                      <div class="per-50 d-flex bottom bet">
                        <div class="qan d-flex bet">
                            <div class="">
                              <h1>{{ tongQuanNhanSu?.tongnv }}</h1>
                              <label>Người</label>
                            </div>
                            <div class="per"> <span>-10% </span></div>
                        </div>
                        
                        <div class="the-month">
                          <div class="select-noborder">
                            <p-dropdown [(ngModel)]="selectedMonth" name="selectedMonth" [options]="months" (onChange)="changeMonths($event)" optionLabel="name"></p-dropdown>
                          </div>
                        </div>
                      </div>

                  </div>
                </div>
              </div>
              <!--card-->
            </div>
        </div>
          <div class="col-12">
            <div class="chart-section">
              <div class="d-flex bet ">
                  <div class="des">
                    <h3>Số lượng nhân sự </h3>
                    <!-- <p>Công ty KSFinance</p> -->
                  </div>
                  <div class="select-noborder d-flex bet middle">
                    <label class="mr-2">Năm</label>
                    <p-dropdown [(ngModel)]="selectedYear" name="selectedYear" [options]="years" optionLabel="name" (onChange)="changeYears($event)"></p-dropdown>
                  </div>
              </div>
              <div class="chart" >
                <!-- <div id="legend-so-luong-nhan-su" class="legend-default">
                    <ul></ul>
                </div> -->
                <canvas style="max-height: 250px" id="so-luong-nhan-su" *ngIf="dashboardData"></canvas>
              </div>
            </div>
            <div class="chart-section">
              <div class="d-flex bet middle">
                <div class="desc">
                  <h3>Biến động nhân sự </h3>
                  <p>{{ selectedNode?.label }}</p>
                </div>
                <div id="legend-bien-dong-nhan-su" class="legend-default line">
                    <ul class="d-flex center"></ul>
                </div>
                <div class="select-noborder">
                  <p-dropdown [(ngModel)]="selectedYear" [options]="years" optionLabel="name" (onChange)="changeYears($event)"></p-dropdown>
                </div>
              </div>
              <div class="chart">
                <canvas style="max-height: 200px" id="bien-dong-nhan-su" *ngIf="dashboardData"></canvas>
              </div>
            </div>
        </div>
      </div>
      <div class="col-3">
        <div class="sidebar col-12 pt-0 pl-0">
          <div class="box">
            <h3>Thống kê hợp đồng theo loại hợp đồng</h3>
            <p>{{ theOrginSelected[0]?.label }} - Đến ngày {{ queryDashboard.months}}/{{ queryDashboard.years}}</p>
            <div class="chart">
              <canvas  style="max-width: 180px; margin: auto" id="doughnut" *ngIf="dashboardData"></canvas>
              <div id="legend-doughnut" class="legend-default dou">
                  <ul></ul>
              </div>
            </div>
            <ul>
              <li class="d-flex bet">Hợp đồng sắp hết hạn: {{ dashboardData?.hd_saphethan }}
                <span class="icon">
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.259459 0.278481C0.54816 0.00352719 1.01624 0.00352719 1.30494 0.278481L5.74054 4.50286C6.02924 4.77781 6.02924 5.2236 5.74054 5.49856L1.30494 9.72293C1.01624 9.99789 0.54816 9.99789 0.259459 9.72293C-0.029243 9.44798 -0.029243 9.00219 0.259459 8.72724L4.17232 5.00071L0.259459 1.27418C-0.029243 0.999223 -0.029243 0.553435 0.259459 0.278481Z" fill="#99A2BC"/>
                    </svg>
                </span>
              </li>
              <li class="d-flex bet">Hợp đồng quá hạn: {{ dashboardData?.hd_quahan }}
                <span class="icon">
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.259459 0.278481C0.54816 0.00352719 1.01624 0.00352719 1.30494 0.278481L5.74054 4.50286C6.02924 4.77781 6.02924 5.2236 5.74054 5.49856L1.30494 9.72293C1.01624 9.99789 0.54816 9.99789 0.259459 9.72293C-0.029243 9.44798 -0.029243 9.00219 0.259459 8.72724L4.17232 5.00071L0.259459 1.27418C-0.029243 0.999223 -0.029243 0.553435 0.259459 0.278481Z" fill="#99A2BC"/>
                    </svg>
                </span>
              </li>
            </ul>
          </div>
          <div class="box">
            <h3>Thống kê hợp đồng theo loại hợp đồng</h3>
            <p>{{ theOrginSelected[0]?.label }} - Đến ngày {{ queryDashboard.months}}/{{ queryDashboard.years}}</p>
            <!-- <div class="chart" style="max-width: 300px; margin: auto">
              <p-chart type="doughnut" [data]="dataDou" [style]="{'width': '40%'}"></p-chart>
            </div> -->
            <div class="chart">
              <canvas  style="max-width: 180px; margin: auto" id="doughnut-2" *ngIf="dashboardData"></canvas>
              <div id="legend-doughnut-2" class="legend-default dou">
                <ul></ul>
            </div>
            </div>
          </div>
        </div>
      </div>
      <!-- row 2 -->
      <!-- <div class="row custom-card">
          <div class="col-lg-6">
              <div class="card mb-1">
                  <div class="card-header card-header-custom">
                      <h4 class="card-title"><i class="fa fa-users"></i> Khách hàng theo nhóm</h4>
                  </div>
              </div>
              <div class="card">
                <div class="card-header">
                    <h4 class="card-title"><i class="fa fa-clock-o"></i> Lịch làm việc trong ngày</h4>
                </div>
                <div class="card-content1"> 
                  <div class="grid-default border">
                    <app-list-grid-angular [listsData]="dataLamViecTrongNgay" [height]="500"
                        [columnDefs]="columnDefs"></app-list-grid-angular>
                  </div>
                </div> 
            </div>
            <div class="col-lg-6">
                <div class="card mb-1">
                    <div class="card-header card-header-custom">
                        <h4 class="card-title"><i class="ft-arrow-up"></i> Tăng trưởng khách hàng hàng năm</h4>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header">
                        <h4 class="card-title"><i class="fa fa-commenting"></i> Hỗ trợ khách hàng</h4>
                    </div>
                    <div class="card-content1"> 
                      <div class="grid-default border">
                        <app-list-grid-angular  [listsData]="dataHoTroKhachHang" [height]="500"
                            [columnDefs]="columnDefs1"></app-list-grid-angular>
                          </div>
                    </div> 
                  </div>
                </div>
              </div>
          </div> -->
    </div>
</div>

<p-dialog styleClass="hr-diagram" [appendTo]="'body'" [autoZIndex]="true" [(visible)]="isHrDiagram" [modal]="true" [breakpoints]="{'1024': '95vw', '640px': '100vw'}" [style]="{width: '90vw'}">
  <p-organizationChart [value]="listAgencyMap" #mydiv  [preserveSpace]="false" selectionMode="single" [(selection)]="selectedNode" (onNodeSelect)="onNodeSelect($event)"
            styleClass="company">
            <ng-template let-node pTemplate="person">
                <div class="node-header p-corner-top">{{node.data.position ? node.data.position : 'No position'}}</div>
                <div class="node-content">
                    <img src="{{node.data.avatarUrl ? node.data.avatarUrl : '../../../../../assets/images/img_avatar.png'}}" width="32">
                    <div>{{node.data.full_name ? node.data.full_name : 'No name'}}</div>
                </div>
            </ng-template>
            <ng-template let-node pTemplate="department" >
                {{node.label}}
            </ng-template>
         
        </p-organizationChart>
</p-dialog>