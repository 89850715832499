<p-button *ngIf="isShowButton" label="Add row" styleClass="p-button-sm add-a-row" (click)="addRow()"></p-button>
<div class="section">
  <ag-grid-angular 
    [style.height]="domLayout === '' ? height + 'px' : ''"
    [excelStyles]="excelStyles"
    [getContextMenuItems]="getContextMenuItems"
     #agGrid style="width: 100% "
     id="{{idGrid}}"
    class="ag-theme-alpine"
    [pinnedTopRowData]="pinnedTopRowData"
    [domLayout]="domLayout"
    [defaultExcelExportParams]="excelExportParams"
    [defaultCsvExportParams]="csvExportParams"
    [rowData]="listsData"
    [columnDefs]="columnDefs"
    [skipHeaderOnAutoSize]="false"
    [floatingFilter]="floatingFilter"
    [isRowMaster]="isRowMaster"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [masterDetail]="true"
    [stopEditingWhenCellsLoseFocus]="true"    [modules]="modules"
    [groupDisplayType]="'groupRows'"
    [rowClassRules]="rowClassRules"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [detailCellRendererParams]="detailCellRendererParams"
    [tooltipShowDelay]="tooltipShowDelay"
    (bodyScroll)="handleScroll($event)"
    [rowSelection]="rowSelection"
    (gridReady)="onGridReady($event)"
    (rowDoubleClicked)="RowDoubleClicked($event)"
    (cellDoubleClicked)="CellDoubleClicked($event)"
    [defaultColDef]="defaultColDef"
    [enableCellTextSelection]="true"
    [suppressCopyRowsToClipboard]="true"
    [groupDisplayType]="'groupRows'"
    [enableRangeSelection]="true"
    [groupUseEntireRow]="true"
    [getRowHeight]="getRowHeight"
    (rowSelected)="onRowSelected($event)"
    (cellValueChanged)="onCellValueChanged($event)"
    [accentedSort]="true"
    (cellClicked)="CellClicked($event)"
    [isRowSelectable]="isRowSelectable"
    (firstDataRendered)="onFirstDataRendered($event)"
    [frameworkComponents]="frameworkComponents">
  </ag-grid-angular>
</div>
<!-- [sideBar]="sideBar" -->

<!-- [suppressRowClickSelection]="true" -->
